<script>
import {queryServiceWorkFile,deleteWorkFile} from '@/apis/serviceorder'
import {downFile,fileSize,iconClass} from '@/apis/common'
import simplebar from "simplebar-vue"
import vue2Dropzone from "vue2-dropzone"
export default {
  props:{
    serviceOrder:{
      type: Object,
      required: true
    }
  },
  components: {simplebar,vueDropzone: vue2Dropzone},
  data() {
    return {
      uploadFileList:[],
      workFileList:[],
      error:false,
      errorMsg:"",
      dropzoneOptions: {
        url: "/api-gateway/service/uploadWorkFile",
        headers: { "Authorization": localStorage.getItem("auth.token")},
        thumbnailWidth: 80,
        maxFilesize: 100,
        addRemoveLinks: true,
        paramName:"files",
      },
    };
  },
  computed:{
    showUpload(){
      if(this.serviceOrder.serviceStatus=="F"){
        return false
      }else{
        return true
      }
    }
  },
  watch: {
    "serviceOrder.serviceId"(newValue){
      if(newValue!=null){
        this.loadData();
      }
    }
  },
  created(){
  },
  mounted() {
  },
  methods: {
    handleDownload (item){
        const url=`/api-gateway/service/download?fileType=SERVICE&fileName=${item.fileOrgName}`
        downFile(url,item.fileName);
    },
    handleDelete(fileName){
      let serviceId = this.serviceOrder.serviceId
      this.workFileList.forEach((item, index) => {
        if (item.fileOrgName==fileName) {
          deleteWorkFile({"id":serviceId,"type":"workFile",fileName:fileName}).then((res)=>{
            if(res.data){
              this.workFileList.splice(index, 1);
            }
          });         
        }
      });
    },
    handlerFileSending: function (file, xhr, formData) {
        formData.append("type","workFile")
        formData.append("id",this.serviceOrder.serviceId)
    },
    // eslint-disable-next-line no-unused-vars
    handlerFileSendingComplete: function (file, xhr, formData) {
        let uploadFile = JSON.parse(file.xhr.response).data[0]
        this.workFileList.push(uploadFile)
        this.uploadFileList.push(file)
    },
    removeUploadFile(file){
      let uploadFile = JSON.parse(file.xhr.response).data[0]
      this.handleDelete(uploadFile.fileOrgName)
    },
    loadData(){
      queryServiceWorkFile(this.serviceOrder.serviceId).then((res)=>{
        this.workFileList = res.data
      })
    },
    fileSize,
    iconClass
  }
};
</script>

<template>
  <div class="mb-0">
    <div class="row">
      <div v-if="workFileList.length>0">
                                    <div class="table-responsive">
                                        <simplebar class="h-100">
                                            <table class="table align-middle table-nowrap table-hover mb-0">
                                                <thead class="table-light">
                                                    <tr>
                                                        <th scope="col">File Name</th>
                                                        <th scope="col">Upload Date</th>
                                                        <th scope="col">Size</th>
                                                        <th scope="col"><a href="javascript:"  @click="loadData"><i class="ion ion-md-refresh me-1 align-middle" ></i> {{$t("actions.refresh")}}</a></th>
                                                    </tr>
                                                    </thead>
                                                <tbody>
                                                    <tr v-for="item in workFileList" :key="item.fileOrgName">
                                                        <td><a @click="handleDownload(item)" href="javascript:" class="text-dark fw-medium"><i class="mdi font-size-16 align-middle me-2" :class="iconClass(item.fileName)"></i> {{item.fileName}}</a></td>
                                                        <td>{{item.createDate}}</td>
                                                        <td>{{fileSize(item.size)}}</td>
                                                        <td>
                                                            <a  @click="handleDownload(item)" href="javascript:">{{$t("common.download")}}</a>
                                                            <a  @click="handleDelete(item.fileOrgName)" href="javascript:" class="ms-2" v-if="showUpload">{{$t("common.delete")}}</a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </simplebar>
                                    </div>
                                </div>
                                <div class="card-body" v-if="showUpload">
                                    <div class="col-lg-12 col-sm-12">
                                      <h4 class="card-title">{{$t("serviceOrder.uploadFile")}}</h4>
                                      <div class="mb-5">
                                      <!-- file upload -->
                                      <vue-dropzone id="dropzone" ref="myVueDropzone" :options="dropzoneOptions"
                                      @vdropzone-sending="handlerFileSending"
                                      @vdropzone-complete="handlerFileSendingComplete"
                                      @vdropzone-removed-file="removeUploadFile"
                                      ></vue-dropzone>
                                      </div>
                                    </div>
                                </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.infoField{
  font-weight: bold;
}
.infoData{
  min-height: 30px;
}
</style>