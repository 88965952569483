<script>
import higg from "./higg";
import slcp from "./slcp";
import general from "./general";
import aws from "./aws";
export default {
  props:{
    serviceOrder:{
      type: Object,
      required: true
    }
  },
  components: {higg,slcp,general,aws},
  data() {
    return {
    };
  },
};
</script>

<template>
  <div class="mb-0">
    <div class="row">
      <higg :serviceOrder="serviceOrder" v-if="serviceOrder.serviceType=='Higg'"></higg>
      <slcp :serviceOrder="serviceOrder" v-if="serviceOrder.serviceType=='SLCP'"></slcp>
      <aws :serviceOrder="serviceOrder" v-if="serviceOrder.serviceType=='AWS'"></aws>
      <general :serviceOrder="serviceOrder" v-if="serviceOrder.serviceType!='SLCP' && serviceOrder.serviceType!='Higg'&& serviceOrder.serviceType!='AWS'"></general>
    </div>             
  </div>
</template>
<style lang="scss" scoped>
.infoField{
  font-weight: bold;
}
.infoData{
  min-height: 30px;
}
</style>