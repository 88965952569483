<script>
import {lookup2,getLookupItemText} from '@/apis/common'
export default {
  props:{
    serviceOrder:{
      type: Object,
      required: true
    }
  },
  components: {},
  data() {
    return {
      orderDetail:{},
      company:{},
      contact:{},
      lookupRawData:{},
    };
  },
  computed:{
    country(){
      return getLookupItemText(this.lookupRawData["CNTY"],this.company.country);
    },
    experience(){
      if(this.orderDetail.experience==1){
        return this.$t("slcp.experience1")
      }else if(this.orderDetail.experience==2){
        return this.$t("slcp.experience2")+" - "+this.orderDetail.experienceNum
      }else{
        return this.$t("slcp.experience3")
      }
    },
    model(){
      if(this.orderDetail.model=="Onsite"){
        return this.$t("slcp.model1")
      }else if(this.orderDetail.model=="All"){
        return this.$t("slcp.model3")
      }else{
        return this.$t("slcp.model2")
      }
    },
    auditDate(){
      return this.orderDetail.auditDateFrom +"/"+this.orderDetail.auditDateTo
    },
    selfassessment(){
      if(this.orderDetail.selfassessment=="y"){
        return this.$t("common.yes") + " " +this.orderDetail.postDate
      }else{
        return this.$t("common.no")
      }
    },
    apsca(){
      return this.orderDetail.apsca=="y"?this.$t("common.yes") :this.$t("common.no")
    },
    announcementType(){
      if(this.orderDetail.announcementType==1){        
        return this.$t("slcp.announcementType1")
      }else if(this.orderDetail.announcementType==3){
        return this.$t("slcp.announcementType3")+" - "+this.orderDetail.days
      }else{
        return this.$t("slcp.announcementType2")
      }
    },
    ahSystem(){
      if(this.orderDetail.ahSystem=="higg"){
        return "Higg:"+this.orderDetail.higgID
      }else if(this.orderDetail.ahSystem=="ffc"){
        return "FFC:"+this.orderDetail.ffcID
      }else{
        return "Other:"+this.orderDetail.otherID
      }
    }
  },
  watch: {
  },
  created(){
  },
  mounted() {
    this.init()
  },
  methods: {
    async init(){
      // 初始化数据字典
      this.lookupRawData = await lookup2("CNTY");
      this.initDetail()
    },
    initDetail(){
      this.contact = {
        contact:this.serviceOrder.order.contact,
        title:this.serviceOrder.order.title,
        mobile:this.serviceOrder.order.mobile,
        email:this.serviceOrder.order.email,
      }
      let orderDetail = JSON.parse(this.serviceOrder.order.orderDetail)
      let companyInfo = JSON.parse(this.serviceOrder.order.companyInfo)
      this.company = {...this.serviceOrder.order,...companyInfo}
      this.orderDetail = {...orderDetail}
    }
  }
};
</script>

<template>
  <div class="mb-0">
      <div class="card border shadow-none mb-3">
            <div class="card-header d-flex align-items-center">
                <div class="flex-shrink-0 me-3">
                    <div class="avatar">
                        <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                            01
                        </div>
                    </div>
                </div>
                <div class="flex-grow-1">
                    <h5 class="card-title">{{ $t("common.verfRequirement") }}</h5>
                </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-lg-6 mb-3">
                  <div class="infoField">{{ $t("slcp.type") }}</div>
                  <div class="infoData">{{ orderDetail.type}}</div>
                </div>
                <div class="col-lg-6 mb-3">
                  <div class="infoField">{{ $t("slcp.experience") }}</div>
                  <div class="infoData">{{ experience}}</div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6  mb-3">
                  <div class="infoField">{{ $t("slcp.selfassessment") }}</div>
                  <div class="infoData">{{selfassessment }}</div>
                </div>
                <div class="col-lg-6  mb-3">
                  <div class="infoField">{{ $t("slcp.postDate") }}</div>
                  <div class="infoData">{{ orderDetail.postDate }}</div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6  mb-3">
                  <div class="infoField">{{ $t("slcp.ahSystem") }}</div>
                  <div class="infoData">{{ahSystem }}</div>
                </div>
                <div class="col-lg-6  mb-3">
                  <div class="infoField">{{ $t("slcp.promoteBrand") }}</div>
                  <div class="infoData">{{ company.promoteBrand }}</div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6  mb-3">
                  <div class="infoField">{{ $t("slcp.announcementType") }}</div>
                  <div class="infoData">{{ announcementType }}</div>
                </div>

                <div class="col-lg-6  mb-3">
                  <div class="infoField">{{ $t("slcp.model") }}</div>
                  <div class="infoData">{{ model }}</div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6  mb-3">
                  <div class="infoField">{{ $t("slcp.auditDate") }}</div>
                  <div class="infoData">{{ auditDate }}</div>
                </div>
                <div class="col-lg-6  mb-3">
                  <div class="infoField">{{ $t("slcp.apsca") }}</div>
                  <div class="infoData">{{ apsca }}</div>
                </div>
              </div>
            </div>
      </div>
      <div class="card border shadow-none mb-3">
          <div class="card-header d-flex align-items-center">
              <div class="flex-shrink-0 me-3">
                  <div class="avatar">
                      <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                          02
                      </div>
                  </div>
              </div>
              <div class="flex-grow-1">
                  <h5 class="card-title">{{$t("common.baseInfo")}}</h5>
              </div>
          </div>
          <div class="card-body">
              <div>
                <div class="row">
                      <div class="col-lg-6 mb-2">
                        <div class="infoField">{{$t("common.clientNameLocal")}}</div>
                        <div class="infoData">{{company.clientName}}</div>
                      </div>
                      <div class="col-lg-6 mb-2">
                        <div class="infoField">{{$t("common.clientNameEn")}}</div>
                        <div class="infoData">{{company.clientNameEn}}</div>
                      </div>
                  </div>
                  <div class="row">
                      <div class="col-lg-12 mb-2">
                        <div class="infoField">{{$t("common.taxCode")}}</div>
                        <div class="infoData">{{company.taxCode}}</div>
                      </div>
                  </div>      
                  <div class="row">
                      <div class="col-lg-6 mb-2">
                        <div class="infoField">{{$t("common.country")}}</div>
                        <div class="infoData">{{country}}</div>
                      </div>
                      <div class="col-lg-6 mb-2">
                        <div class="infoField">{{$t("common.province")}}</div>
                        <div class="infoData">{{company.province}}</div>
                      </div>
                  </div>                              
                  <div class="row">
                      <div class="col-lg-6 mb-2">
                        <div class="infoField">{{$t("common.addressLocal")}}</div>
                        <div class="infoData">{{company.address}}</div>
                      </div>
                      <div class="col-lg-6 mb-2">
                        <div class="infoField">{{$t("common.addressEn")}}</div>
                        <div class="infoData">{{company.addressEn}}</div>
                      </div>
                  </div>
                  <div class="row">
                      <div class="col-lg-12 mb-2">
                        <div class="infoField">{{$t("common.station")}}</div>
                        <div class="infoData">{{company.station}}</div>
                      </div>
                  </div>            
                  <div class="row">
                    <div class="col-lg-6 mb-2">
                        <div class="infoField">{{$t("slcp.employee")}}</div>
                        <div class="infoData">{{company.employee}}</div>
                      </div>
                      <div class="col-lg-6 mb-2">
                        <div class="infoField">{{$t("slcp.manager")}}</div>
                        <div class="infoData">{{company.manager}}</div>
                      </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6 mb-2">
                        <div class="infoField">{{$t("slcp.productionBuildings")}}</div>
                        <div class="infoData">{{company.productionBuildings}}</div>
                      </div>
                      <div class="col-lg-6 mb-2">
                        <div class="infoField">{{$t("slcp.dormitories")}}</div>
                        <div class="infoData">{{company.dormitories}}</div>
                      </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6 mb-2">
                        <div class="infoField">{{$t("slcp.warehouses")}}</div>
                        <div class="infoData">{{company.warehouses}}</div>
                      </div>
                      <div class="col-lg-6 mb-2">
                        <div class="infoField">{{$t("slcp.otherBuildings")}}</div>
                        <div class="infoData">{{company.otherBuildings}}</div>
                      </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6 mb-2">
                        <div class="infoField">{{$t("slcp.productType")}}</div>
                        <div class="infoData">{{company.productType}}</div>
                      </div>
                      <div class="col-lg-6 mb-2">
                        <div class="infoField">{{$t("slcp.technology")}}</div>
                        <div class="infoData">{{company.technology}}</div>
                      </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6 mb-2">
                        <div class="infoField">{{$t("slcp.csr")}}</div>
                        <div class="infoData">{{company.csr}}</div>
                      </div>
                      <div class="col-lg-6 mb-2">
                        <div class="infoField">{{$t("slcp.serviceProvider")}}</div>
                        <div class="infoData">{{company.serviceProvider}}</div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div class="card border shadow-none mb-3">
          <div class="card-header d-flex align-items-center">
              <div class="flex-shrink-0">
                  <div class="avatar">
                      <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                          03
                      </div>
                  </div>
              </div>
              <div class="flex-grow-1">
                  <h5 class="card-title">{{$t("common.contactInfo")}}</h5>
              </div>
          </div>
          <div class="card-body">
              <div>
                <div class="row">
                    <div class="col-lg-6 mb-2">
                        <div class="infoField">{{$t("common.contact")}}</div>
                        <div class="infoData">{{contact.contact}}</div>
                      </div>
                      <div class="col-lg-6 mb-2">
                        <div class="infoField">{{$t("common.title")}}</div>
                        <div class="infoData">{{contact.title}}</div>
                      </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                        <div class="infoField">{{$t("common.mobile")}}</div>
                        <div class="infoData">{{contact.mobile}}</div>
                      </div>
                      <div class="col-lg-6">
                        <div class="infoField">{{$t("common.email")}}</div>
                        <div class="infoData">{{contact.email}}</div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>
<style lang="scss" scoped>
.infoField{
  font-weight: bold;
}
.infoData{
  min-height: 30px;
}
</style>