<script>
import {downFile,fileSize,iconClass} from '@/apis/common'
import {queryServiceClientFile} from '@/apis/serviceorder'
import simplebar from "simplebar-vue"
export default {
  props:{
    serviceOrder:{
      type: Object,
      required: true
    }
  },
  components: {simplebar},
  data() {
    return {
      clientFileList:[]
    };
  },
  computed:{
  },
  watch: {
    "serviceOrder.serviceId"(newValue){
      if(newValue!=null){
        this.loadData();
      }
    }
  },
  created(){
  },
  mounted() {
  },
  methods: {
    handleDownload (item){
        const url=`/api-gateway/service/download?fileType=SERVICE&fileName=${item.fileOrgName}`
        downFile(url,item.fileName);
    },
    loadData(){
      queryServiceClientFile(this.serviceOrder.serviceId,1).then((res)=>{
        this.clientFileList = res.data
      })
    },
    fileSize,
    iconClass
  }
};
</script>

<template>
  <div class="mb-0">
    <div class="row">
      <div class="table-responsive">
          <simplebar class="h-100">
              <table class="table align-middle table-nowrap table-hover mb-0">
                  <thead class="table-light">
                      <tr>
                          <th scope="col">File Name</th>
                          <th scope="col">Upload Date</th>
                          <th scope="col">Size</th>
                          <th scope="col"><a href="javascript:"  @click="loadData"><i class="ion ion-md-refresh me-1 align-middle" ></i> {{$t("actions.refresh")}}</a></th>
                      </tr>
                      </thead>
                  <tbody>
                      <tr v-for="item in clientFileList" :key="item.fileOrgName">
                          <td><a @click="handleDownload(item)" href="javascript:" class="text-dark fw-medium"><i class="mdi font-size-16 align-middle me-2" :class="iconClass(item.fileName)"></i> {{item.fileName}}</a></td>
                          <td>{{item.createDate}}</td>
                          <td>{{fileSize(item.size)}}</td>
                          <td>
                              <a  @click="handleDownload(item)" href="javascript:">{{$t("common.download")}}</a>
                          </td>
                      </tr>
                  </tbody>
              </table>
          </simplebar>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.infoField{
  font-weight: bold;
}
.infoData{
  min-height: 30px;
}
</style>