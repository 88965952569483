<script>
import {lookup2,getLookupItemText} from '@/apis/common'
export default {
  props:{
    serviceOrder:{
      type: Object,
      required: true
    }
  },
  components: {},
  data() {
    return {
      orderDetail:{},
      company:{},
      contact:{},
      lookupRawData:{},
      lookupData:{
        businessType:[],
        industry:[],
        travel:[]
      },
    };
  },
  computed:{
    country(){
      return getLookupItemText(this.lookupRawData["CNTY"],this.company.country);
    },    
    orderType(){
      if(this.orderDetail.type=="FEM"){
        return this.$t("fem.typeFEM")
      }else{
        return this.$t("fem.typeFoundation")
      }
    },
    experience(){
      if(this.orderDetail.experience==1){
        return this.$t("fem.experience1")
      }else if(this.orderDetail.experience==2){
        return this.$t("fem.experience2")
      }else{
        return this.$t("fem.experience3")
      }
    },
    model(){
      if(this.orderDetail.model=="Onsite"){
        return this.$t("fem.model1")
      }else{
        return this.$t("fem.model2")
      }
    },
    auditDate(){
      return this.orderDetail.auditDateFrom +"/"+this.orderDetail.auditDateTo
    },
    selfassessment(){
      return this.orderDetail.selfassessment=="y"?this.$t("common.yes"):this.$t("common.no")
    },
    industry(){
      return getLookupItemText(this.lookupRawData["INDUSTRY"],this.company.industry);
    },
    businessType(){
      return getLookupItemText(this.lookupRawData["BUSINESS-TYPE"],this.company.businessType);
    }
  },
  watch: {
  },
  created(){
  },
  mounted() {
    this.init()
  },
  methods: {
    async init(){
      // 初始化数据字典
      this.lookupRawData = await lookup2("BUSINESS-TYPE,INDUSTRY,CNTY");
      this.initDetail()
    },
    initDetail(){
      this.contact = {
        contact:this.serviceOrder.order.contact,
        title:this.serviceOrder.order.title,
        mobile:this.serviceOrder.order.mobile,
        email:this.serviceOrder.order.email,
      }
      let orderDetail = JSON.parse(this.serviceOrder.order.orderDetail)
      let companyInfo = JSON.parse(this.serviceOrder.order.companyInfo)
      this.company = {...this.serviceOrder.order,...companyInfo}
      this.orderDetail = {...orderDetail}
    }
  }
};
</script>

<template>
  <div class="mb-0">
      <div class="card border shadow-none mb-3">
            <div class="card-header d-flex align-items-center">
                <div class="flex-shrink-0 me-3">
                    <div class="avatar">
                        <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                            01
                        </div>
                    </div>
                </div>
                <div class="flex-grow-1">
                    <h5 class="card-title">{{$t("common.verfRequirement")}}</h5>
                </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-12 mb-3">
                  <div class="infoField">{{$t("fem.type")}}</div>
                  <div class="infoData">{{ orderType}}</div>
                </div>
                
              </div>
              <div class="row" v-if="orderDetail.type=='FEM'">
                <div class="col-lg-12 mb-2">
                  <div class="infoField">{{$t("fem.self")}}</div>
                </div>
                <div class="col-lg-5 mb-2 ms-3">
                  <div class="infoField">{{$t("fem.envManagementSystem")}}</div>
                  <div class="infoData">Level {{ orderDetail.envManagementSystem }}</div>
                </div>
                <div class="col-lg-5 mb-2 ms-3">
                  <div class="infoField">{{$t("fem.energyGreenhouseGases")}}</div>
                  <div class="infoData">Level {{ orderDetail.energyGreenhouseGases }}</div>
                </div>
                <div class="col-lg-5 mb-2 ms-3">
                  <div class="infoField">{{$t("fem.waterResources")}}</div>
                  <div class="infoData">Level {{ orderDetail.waterResources }}</div>
                </div>
                <div class="col-lg-5 mb-2 ms-3">
                  <div class="infoField">{{$t("fem.wastewater")}}</div>
                  <div class="infoData">Level {{ orderDetail.wastewater }}</div>
                </div>
                <div class="col-lg-5 mb-2 ms-3">
                  <div class="infoField">{{$t("fem.exhaustEmissions")}}</div>
                  <div class="infoData">Level {{ orderDetail.exhaustEmissions }}</div>
                </div>
                <div class="col-lg-5 mb-2 ms-3">
                  <div class="infoField">{{$t("fem.waste")}}</div>
                  <div class="infoData">Level {{ orderDetail.waste }}</div>
                </div>
                <div class="col-lg-5 mb-2 ms-3">
                  <div class="infoField">{{$t("fem.chemicalManagement")}}</div>
                  <div class="infoData">Level {{ orderDetail.chemicalManagement }}</div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6  mb-3">
                  <div class="infoField">{{$t("fem.experience")}}</div>
                  <div class="infoData">{{experience }}</div>
                </div>
                <div class="col-lg-6  mb-3">
                  <div class="infoField">{{$t("fem.model")}}</div>
                  <div class="infoData">{{ model }}</div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6  mb-3">
                  <div class="infoField">{{$t("fem.selfassessment")}}</div>
                  <div class="infoData">{{ selfassessment }}</div>
                </div>
                <div class="col-lg-6  mb-3">
                  <div class="infoField">{{$t("fem.auditDate")}}</div>
                  <div class="infoData">{{ auditDate }}</div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <div class="infoField">Higg ID</div>
                  <div class="infoData">{{ orderDetail.higgID }}</div>
                </div>
                <div class="col-lg-6">
                  <div class="infoField">{{$t("fem.promoteBrand")}}</div>
                  <div class="infoData">{{ company.promoteBrand }}</div>
                </div>                
              </div>
            </div>
      </div>
      <div class="card border shadow-none mb-3">
          <div class="card-header d-flex align-items-center">
              <div class="flex-shrink-0 me-3">
                  <div class="avatar">
                      <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                          02
                      </div>
                  </div>
              </div>
              <div class="flex-grow-1">
                  <h5 class="card-title">{{$t("common.baseInfo")}}</h5>
              </div>
          </div>
          <div class="card-body">
              <div>
                  <div class="row">
                      <div class="col-lg-6 mb-2">
                        <div class="infoField">{{$t("common.clientNameLocal")}}</div>
                        <div class="infoData">{{company.clientName}}</div>
                      </div>
                      <div class="col-lg-6 mb-2">
                        <div class="infoField">{{$t("common.clientNameEn")}}</div>
                        <div class="infoData">{{company.clientNameEn}}</div>
                      </div>
                  </div>
                  <div class="row">
                      <div class="col-lg-12 mb-2">
                        <div class="infoField">{{$t("common.taxCode")}}</div>
                        <div class="infoData">{{company.taxCode}}</div>
                      </div>
                  </div>      
                  <div class="row">
                      <div class="col-lg-6 mb-2">
                        <div class="infoField">{{$t("common.country")}}</div>
                        <div class="infoData">{{country}}</div>
                      </div>
                      <div class="col-lg-6 mb-2">
                        <div class="infoField">{{$t("common.province")}}</div>
                        <div class="infoData">{{company.province}}</div>
                      </div>
                  </div>                              
                  <div class="row">
                      <div class="col-lg-6 mb-2">
                        <div class="infoField">{{$t("common.addressLocal")}}</div>
                        <div class="infoData">{{company.address}}</div>
                      </div>
                      <div class="col-lg-6 mb-2">
                        <div class="infoField">{{$t("common.addressEn")}}</div>
                        <div class="infoData">{{company.addressEn}}</div>
                      </div>
                  </div>
                  <div class="row">
                      <div class="col-lg-12 mb-2">
                        <div class="infoField">{{$t("common.station")}}</div>
                        <div class="infoData">{{company.station}}</div>
                      </div>
                  </div>                     
                  <div class="row">
                    <div class="col-lg-6 mb-2">
                        <div class="infoField">{{$t("common.industry")}}</div>
                        <div class="infoData">{{industry}}</div>
                      </div>
                      <div class="col-lg-6 mb-2">
                        <div class="infoField">{{$t("common.businessType")}}</div>
                        <div class="infoData">{{businessType}}</div>
                      </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6 mb-2">
                        <div class="infoField">{{$t("fem.productType")}}</div>
                        <div class="infoData">{{company.productType}}</div>
                      </div>
                      <div class="col-lg-6 mb-2">
                        <div class="infoField">{{$t("fem.technology")}}</div>
                        <div class="infoData">{{company.technology}}</div>
                      </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6 mb-2">
                        <div class="infoField">{{$t("fem.chemical")}}</div>
                        <div class="infoData">{{company.chemical}}</div>
                      </div>
                      <div class="col-lg-6 mb-2">
                        <div class="infoField">{{$t("fem.wwt")}}</div>
                        <div class="infoData">{{company.wwt}}</div>
                      </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6 mb-2">
                        <div class="infoField">{{$t("fem.coverarea")}}</div>
                        <div class="infoData">{{company.coverarea}}</div>
                      </div>
                      <div class="col-lg-6 mb-2">
                        <div class="infoField">{{$t("fem.employee")}}</div>
                        <div class="infoData">{{company.employee}}</div>
                      </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="infoField">{{$t("fem.esc")}}</div>
                      <div class="infoData">{{company.esc}}</div>
                    </div>
                  </div>
              </div>
          </div>
      </div>
      <div class="card border shadow-none mb-3">
          <div class="card-header d-flex align-items-center">
              <div class="flex-shrink-0">
                  <div class="avatar">
                      <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                          03
                      </div>
                  </div>
              </div>
              <div class="flex-grow-1">
                  <h5 class="card-title">{{$t("common.contactInfo")}}</h5>
              </div>
          </div>
          <div class="card-body">
              <div>
                <div class="row">
                    <div class="col-lg-6 mb-2">
                        <div class="infoField">{{$t("common.contact")}}</div>
                        <div class="infoData">{{contact.contact}}</div>
                      </div>
                      <div class="col-lg-6 mb-2">
                        <div class="infoField">{{$t("common.title")}}</div>
                        <div class="infoData">{{contact.title}}</div>
                      </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                        <div class="infoField">{{$t("common.mobile")}}</div>
                        <div class="infoData">{{contact.mobile}}</div>
                      </div>
                      <div class="col-lg-6">
                        <div class="infoField">{{$t("common.email")}}</div>
                        <div class="infoData">{{contact.email}}</div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>
<style lang="scss" scoped>
.infoField{
  font-weight: bold;
}
.infoData{
  min-height: 30px;
}
</style>