<script>
import {lookup2,getLookupItemText} from '@/apis/common'
export default {
  props:{
    serviceOrder:{
      type: Object,
      required: true
    }
  },
  components: {},
  data() {
    return {
      orderDetail:{},
      company:{},
      contact:{},
      lookupRawData:{},
      lookupData:{
        certLev:[],
        certType:[],
        auditType:[],
        wtSource:[],
        wwChannel:[],                
        cnty:[]
      },
    };
  },
  computed:{
    country(){
      return getLookupItemText(this.lookupRawData["CNTY"],this.company.country);
    },    
    certLev(){
      return getLookupItemText(this.lookupRawData["AWS-CERT-LEV"],this.orderDetail.certLev);
    },    
    certType(){
      return getLookupItemText(this.lookupRawData["AWS-CERT-TYPE"],this.orderDetail.certType);
    },    
    auditType(){
      return getLookupItemText(this.lookupRawData["AWS-AUDIT-TYPE"],this.orderDetail.auditType);
    },    
    wtSource(){
      return getLookupItemText(this.lookupRawData["AWS-WT-SOURCE"],this.orderDetail.wtSource);
    },    
    wwChannel(){
      return getLookupItemText(this.lookupRawData["AWS-WW-CHANNEL"],this.orderDetail.wwChannel);
    },    
  },
  watch: {
  },
  created(){
  },
  mounted() {
    this.init()
  },
  methods: {
    async init(){
      // 初始化数据字典
      this.lookupRawData = await lookup2("AWS-CERT-LEV,AWS-CERT-TYPE,AWS-AUDIT-TYPE,AWS-WT-SOURCE,AWS-WW-CHANNEL,CNTY");
      this.initDetail()
    },
    initDetail(){
      this.contact = {
        contact:this.serviceOrder.order.contact,
        title:this.serviceOrder.order.title,
        mobile:this.serviceOrder.order.mobile,
        email:this.serviceOrder.order.email,
      }
      let orderDetail = JSON.parse(this.serviceOrder.order.orderDetail)
      let companyInfo = JSON.parse(this.serviceOrder.order.companyInfo)
      this.company = {...this.serviceOrder.order,...companyInfo}
      this.orderDetail = {...orderDetail}
    }
  }
};
</script>

<template>
  <div class="mb-0">
      <div class="card border shadow-none mb-3">
            <div class="card-header d-flex align-items-center">
                <div class="flex-shrink-0 me-3">
                    <div class="avatar">
                        <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                            01
                        </div>
                    </div>
                </div>
                <div class="flex-grow-1">
                    <h5 class="card-title">{{$t("aws.applicantInformation")}}</h5>
                </div>
            </div>
            <div class="card-body">
              <div class="row">
                      <div class="col-lg-6 mb-2">
                        <div class="infoField">{{$t("common.clientNameLocal")}}</div>
                        <div class="infoData">{{company.clientName}}</div>
                      </div>
                      <div class="col-lg-6 mb-2">
                        <div class="infoField">{{$t("common.clientNameEn")}}</div>
                        <div class="infoData">{{company.clientNameEn}}</div>
                      </div>
                  </div>
                  <div class="row">
                      <div class="col-lg-12 mb-2">
                        <div class="infoField">{{$t("common.taxCode")}}</div>
                        <div class="infoData">{{company.taxCode}}</div>
                      </div>
                  </div>      
                  <div class="row">
                      <div class="col-lg-6 mb-2">
                        <div class="infoField">{{$t("common.country")}}</div>
                        <div class="infoData">{{country}}</div>
                      </div>
                      <div class="col-lg-6 mb-2">
                        <div class="infoField">{{$t("common.province")}}</div>
                        <div class="infoData">{{company.province}}</div>
                      </div>
                  </div>                              
                  <div class="row">
                      <div class="col-lg-6 mb-2">
                        <div class="infoField">{{$t("common.addressLocal")}}</div>
                        <div class="infoData">{{company.address}}</div>
                      </div>
                      <div class="col-lg-6 mb-2">
                        <div class="infoField">{{$t("common.addressEn")}}</div>
                        <div class="infoData">{{company.addressEn}}</div>
                      </div>
                  </div>
                  <div class="row">
                      <div class="col-lg-12 mb-2">
                        <div class="infoField">{{$t("common.station")}}</div>
                        <div class="infoData">{{company.station}}</div>
                      </div>
                  </div>                     
                  <div class="row">
                    <div class="col-lg-6 mb-2">
                        <div class="infoField">{{$t("common.contact")}}</div>
                        <div class="infoData">{{contact.contact}}</div>
                      </div>
                      <div class="col-lg-6 mb-2">
                        <div class="infoField">{{$t("common.title")}}</div>
                        <div class="infoData">{{contact.title}}</div>
                      </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                        <div class="infoField">{{$t("common.mobile")}}</div>
                        <div class="infoData">{{contact.mobile}}</div>
                      </div>
                      <div class="col-lg-6">
                        <div class="infoField">{{$t("common.email")}}</div>
                        <div class="infoData">{{contact.email}}</div>
                      </div>
                  </div>
            </div>
      </div>
      <div class="card border shadow-none mb-3">
          <div class="card-header d-flex align-items-center">
              <div class="flex-shrink-0 me-3">
                  <div class="avatar">
                      <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                          02
                      </div>
                  </div>
              </div>
              <div class="flex-grow-1">
                  <h5 class="card-title">{{$t("aws.facility")}}</h5>
              </div>
          </div>
          <div class="card-body">
              <div>
                  
                  <div class="row">
                    <div class="col-lg-12 mb-2">
                        <div class="infoField">{{$t("aws.desiredCertificationLevel")}}</div>
                        <div class="infoData">{{certLev}}</div>
                      </div>
                  </div>
                  <div class="row">
                      <div class="col-lg-12 mb-2">
                        <div class="infoField">{{$t("aws.certificationType")}}</div>
                        <div class="infoData">{{certType}}</div>
                      </div>
                  </div>
                  <div class="row">
                      <div class="col-lg-12 mb-2">
                        <div class="infoField">{{$t("aws.auditType")}}</div>
                        <div class="infoData">{{auditType}}</div>
                      </div>
                  </div>
                  <div class="row" v-if="orderDetail.auditType=='T'">
                        <div class="col-lg-12 mb-3">
                          <div class="infoField">{{$t("aws.reason")}}</div>
                          <div class="infoData">{{orderDetail.reason}}</div>          
                        </div>
                  </div>    
                  <div class="row">
                    <div class="col-lg-6 mb-2">
                        <div class="infoField">{{$t("aws.factoryName")}}</div>
                        <div class="infoData">{{orderDetail.factoryName}}</div>
                      </div>
                      <div class="col-lg-6 mb-2">
                        <div class="infoField">{{$t("aws.address")}}</div>
                        <div class="infoData">{{orderDetail.address}}</div>
                      </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-4 mb-2">
                        <div class="infoField">{{$t("aws.referenceNumber")}}</div>
                        <div class="infoData">{{orderDetail.referenceNumber}}</div>
                      </div>
                      <div class="col-lg-4 mb-2">
                        <div class="infoField">{{$t("aws.employees")}}</div>
                        <div class="infoData">{{orderDetail.employees}}</div>
                      </div>
                      <div class="col-lg-4 mb-2">
                        <div class="infoField">{{$t("aws.occupiedArea")}}</div>
                        <div class="infoData">{{orderDetail.occupiedArea}}</div>
                      </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6 mb-2">
                        <div class="infoField">{{$t("aws.mainProducts")}}</div>
                        <div class="infoData">{{orderDetail.mainProducts}}</div>
                      </div>
                      <div class="col-lg-6 mb-2">
                        <div class="infoField">{{$t("aws.mainManufacture")}}</div>
                        <div class="infoData">{{orderDetail.mainManufacture}}</div>
                      </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="infoField">{{$t("aws.wastewater")}}</div>
                      <div class="infoData">{{orderDetail.wastewater}}</div>
                    </div>
                    <div class="col-lg-6">
                      <div class="infoField">{{$t("aws.annualWater")}}</div>
                      <div class="infoData">{{orderDetail.annualWater}}</div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="infoField">{{$t("aws.sourceWater")}}</div>
                      <div class="infoData">{{wtSource}}</div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="infoField" v-html="$t('aws.wastewaterReused')"></div>
                      <div class="infoData">{{orderDetail.wastewaterReused}}</div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="infoField" v-html="$t('aws.wastewaterTreatmentPlant')"></div>
                      <div class="infoData">{{orderDetail.wastewaterTreatmentPlant}}</div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="infoField" v-html="$t('aws.dischargedWastewater')"></div>
                      <div class="infoData">{{wwChannel}}</div>
                    </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>
<style lang="scss" scoped>
.infoField{
  font-weight: bold;
}
.infoData{
  min-height: 30px;
}
</style>