<script>
import {lookup2,getLookupItemText} from '@/apis/common'
export default {
  props:{
    serviceOrder:{
      type: Object,
      required: true
    }
  },
  components: {},
  data() {
    return {
      orderDetail:{},
      company:{},
      contact:{},
      lookupRawData:{},
    };
  },
  computed:{
    country(){
      return getLookupItemText(this.lookupRawData["CNTY"],this.company.country);
    },    
    orderType(){
      return getLookupItemText(this.lookupRawData["ORDER-TYPE"],this.serviceOrder.serviceType);
    },
    auditDate(){
      return this.orderDetail.auditDateFrom +"/"+this.orderDetail.auditDateTo
    },
    industry(){
      return getLookupItemText(this.lookupRawData["INDUSTRY"],this.company.industry);
    },
    businessType(){
      return getLookupItemText(this.lookupRawData["BUSINESS-TYPE"],this.company.businessType);
    }
  },
  watch: {
    serviceOrder(){
      this.initDetail()
    }
  },
  created(){
    this.init()
  },
  mounted() {    
  },
  methods: {
    async init(){
      // 初始化数据字典
      this.lookupRawData = await lookup2("BUSINESS-TYPE,INDUSTRY,CNTY,ORDER-TYPE");
    },
    initDetail(){
      this.contact = {
        contact:this.serviceOrder.order.contact,
        title:this.serviceOrder.order.title,
        mobile:this.serviceOrder.order.mobile,
        email:this.serviceOrder.order.email,
      }
      let orderDetail = JSON.parse(this.serviceOrder.order.orderDetail)
      let companyInfo = JSON.parse(this.serviceOrder.order.companyInfo)
      this.company = {...this.serviceOrder.order,...companyInfo}
      this.orderDetail = {...orderDetail}
    }
  }
};
</script>

<template>
  <div class="mb-0">
      <div class="card border shadow-none mb-3">
            <div class="card-header d-flex align-items-center">
                <div class="flex-shrink-0 me-3">
                    <div class="avatar">
                        <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                            01
                        </div>
                    </div>
                </div>
                <div class="flex-grow-1">
                    <h5 class="card-title">{{$t("common.verfRequirement")}}</h5>
                </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-12 mb-3">
                  <div class="infoField">{{$t("generalService.serviceType")}}</div>
                  <div class="infoData">{{ orderType}}</div>
                </div>
                
              </div>
              <div class="row">
                <div class="col-lg-6  mb-3">
                  <div class="infoField">{{$t("generalService.serviceDate")}}</div>
                  <div class="infoData">{{ auditDate }}</div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <div class="infoField">{{$t("fem.promoteBrand")}}</div>
                  <div class="infoData">{{ company.promoteBrand }}</div>
                </div>                
              </div>
            </div>
      </div>
      <div class="card border shadow-none mb-3">
          <div class="card-header d-flex align-items-center">
              <div class="flex-shrink-0 me-3">
                  <div class="avatar">
                      <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                          02
                      </div>
                  </div>
              </div>
              <div class="flex-grow-1">
                  <h5 class="card-title">{{$t("common.baseInfo")}}</h5>
              </div>
          </div>
          <div class="card-body">
              <div>
                  <div class="row">
                      <div class="col-lg-6 mb-2">
                        <div class="infoField">{{$t("common.clientNameLocal")}}</div>
                        <div class="infoData">{{company.clientName}}</div>
                      </div>
                      <div class="col-lg-6 mb-2">
                        <div class="infoField">{{$t("common.clientNameEn")}}</div>
                        <div class="infoData">{{company.clientNameEn}}</div>
                      </div>
                  </div>
                  <div class="row">
                      <div class="col-lg-12 mb-2">
                        <div class="infoField">{{$t("common.taxCode")}}</div>
                        <div class="infoData">{{company.taxCode}}</div>
                      </div>
                  </div>      
                  <div class="row">
                      <div class="col-lg-6 mb-2">
                        <div class="infoField">{{$t("common.country")}}</div>
                        <div class="infoData">{{country}}</div>
                      </div>
                      <div class="col-lg-6 mb-2">
                        <div class="infoField">{{$t("common.province")}}</div>
                        <div class="infoData">{{company.province}}</div>
                      </div>
                  </div>                              
                  <div class="row">
                      <div class="col-lg-6 mb-2">
                        <div class="infoField">{{$t("common.addressLocal")}}</div>
                        <div class="infoData">{{company.address}}</div>
                      </div>
                      <div class="col-lg-6 mb-2">
                        <div class="infoField">{{$t("common.addressEn")}}</div>
                        <div class="infoData">{{company.addressEn}}</div>
                      </div>
                  </div>
                  <div class="row">
                      <div class="col-lg-12 mb-2">
                        <div class="infoField">{{$t("common.station")}}</div>
                        <div class="infoData">{{company.station}}</div>
                      </div>
                  </div>                     
                  <div class="row">
                    <div class="col-lg-6 mb-2">
                        <div class="infoField">{{$t("common.industry")}}</div>
                        <div class="infoData">{{industry}}</div>
                      </div>
                      <div class="col-lg-6 mb-2">
                        <div class="infoField">{{$t("common.businessType")}}</div>
                        <div class="infoData">{{businessType}}</div>
                      </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6 mb-2">
                        <div class="infoField">{{$t("fem.productType")}}</div>
                        <div class="infoData">{{company.productType}}</div>
                      </div>
                      <div class="col-lg-6 mb-2">
                        <div class="infoField">{{$t("fem.technology")}}</div>
                        <div class="infoData">{{company.technology}}</div>
                      </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6 mb-2">
                        <div class="infoField">{{$t("fem.coverarea")}}</div>
                        <div class="infoData">{{company.coverarea}}</div>
                      </div>
                      <div class="col-lg-6 mb-2">
                        <div class="infoField">{{$t("fem.employee")}}</div>
                        <div class="infoData">{{company.employee}}</div>
                      </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="infoField">{{$t("fem.esc")}}</div>
                      <div class="infoData">{{company.esc}}</div>
                    </div>
                  </div>
              </div>
          </div>
      </div>
      <div class="card border shadow-none mb-3">
          <div class="card-header d-flex align-items-center">
              <div class="flex-shrink-0">
                  <div class="avatar">
                      <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                          03
                      </div>
                  </div>
              </div>
              <div class="flex-grow-1">
                  <h5 class="card-title">{{$t("common.contactInfo")}}</h5>
              </div>
          </div>
          <div class="card-body">
              <div>
                <div class="row">
                    <div class="col-lg-6 mb-2">
                        <div class="infoField">{{$t("common.contact")}}</div>
                        <div class="infoData">{{contact.contact}}</div>
                      </div>
                      <div class="col-lg-6 mb-2">
                        <div class="infoField">{{$t("common.title")}}</div>
                        <div class="infoData">{{contact.title}}</div>
                      </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                        <div class="infoField">{{$t("common.mobile")}}</div>
                        <div class="infoData">{{contact.mobile}}</div>
                      </div>
                      <div class="col-lg-6">
                        <div class="infoField">{{$t("common.email")}}</div>
                        <div class="infoData">{{contact.email}}</div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>
<style lang="scss" scoped>
.infoField{
  font-weight: bold;
}
.infoData{
  min-height: 30px;
}
</style>